/* Global CSS  */
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Gotham", sans-serif;
}
:root {
  font-size: 10px;
  --black: #000000;
  --white: #ffffff;
  --primary-blue: #99205A;
}
.box {
  width: 100%;
  max-width: 1500px;
  padding: 0 5rem;
  margin: 0 auto;
}
html,
body {
  width: 100%;
  max-width: 100vw;
  overflow-x: auto;
  scroll-behavior: smooth;
}
a {
  text-decoration: none !important;
}
button {
  cursor: pointer;
}
.padpop {
  width: 100%;
  height: 100%;
}
.padpop-content {
  width: 100%;
}
.primary-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background: linear-gradient(90deg, #B3265B, #E3443D);
  border: unset;
  padding: 0.95rem 4.55rem;
  transform: skewX(-20deg);
  margin: 0 0 0 1rem;
  transition: 0.25s;
  position: relative;
  overflow: hidden;
}

.primary-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F6CA4B;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.primary-btn:hover::before {
  opacity: 1;
}

.primary-btn span {
  color: var(--black);
  font-size: 2.25rem;
  font-weight: 800;
  transform: skewX(20deg);
  text-transform: uppercase;
  position: relative;
  z-index: 1;
}

/* MARQUEE TAG  */
.marquee-box {
  width: 100%;
  background: linear-gradient(90deg, #99205A, #E3433E, #F6CA4A);
  padding: 2.5rem 0;
}
.marquee-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8rem;  
  width: 100%;
  margin: 0 4.75rem 0 0;
}
.marquee-content img {
  width: 90px;
  height: unset;
}
.marquee-content h6 {
  color: var(--white);
  font-size: 2rem;
  font-weight: 600;
  line-height: 150%;
  margin: 0;
}
.logo-image {
  width: auto;
  height: auto;
  max-width: 100%;
  object-fit: contain;
}

/* HERO SECTION  */
.hero-section {
  width: 100%;
  position: relative;
  background-image: url(./assets/imgs/bg-img2.png);
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover; /* Change this from 'cover' to 'contain' */
  background-position: center top; 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0;
  padding: 0 0 28rem 0;
  min-height: 100vh; /* Add this to ensure full viewport height */
  overflow: hidden;
}
.header-section {
  transition: top 0.25s ease, backgroundColor 0.25s ease;
  z-index: 1000;
  position: fixed;
  top: 13.85%;
  width: 100%;
  background-color: transparent;
  padding: 6rem 0;
}
.header-section.scrolled {
  top: 0%;
  background-color: black;
  padding: 2rem 0;
}
.header-section.scrolled .mobile-menu {
  top: 100%;
}
.header-section.scrolled .header-content img {
  max-height: 35px;
}

header {
  width: 100%;  
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  position: relative;
  z-index: 1;
}
.header-content img {
  max-height: 60px;
}
.header-content .padpop {
  width: 230px;
  height: auto;
  cursor: pointer;
}
.header-content .menu-icon {
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.hero-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6rem;
  width: 45%;
  margin: 30rem auto 0 0;
  position: relative;
  z-index: 1;
}
.hero-content h2 {
  color: #F6CA4B;
  font-size: 8rem;
  font-weight: 550;
  line-height: 120%;
  text-transform: uppercase;
  margin: 0;
}

/* ABOUT US SECTION  */
.aboutus-section {
  background-color: var(--black);
  padding: 10rem 0;
}
.aboutus-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 2.35rem;
}
.aboutus-content h3 {
  color: #F6CA4B;
  font-size: 7.45rem;
  font-weight: 600;
  line-height: 120%;
  margin: 0;
  text-transform: uppercase;
}
.aboutus-content p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.85rem;
  font-weight: 400;
  line-height: 150%;
  margin: 0 auto;
  text-align: center;
  width: 65%;
}
.aboutus-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.35rem;
  margin: 5rem 0 0 0;
  width: 100%;
}
.aboutus-flex hr {
  width: 3px;
  height: 150px;
  opacity: 0.1;
  background: var(--white);
}
.au-flex-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.55rem;
}
.au-flex-content h4 {
  font-size: 7.25rem;
  font-weight: 600;
  line-height: 120%;
  margin: 0;
}
/* Add this new rule */
.aboutus-flex-gradient-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}
.au-flex-content:nth-child(1) h4 {
  color: #99205A;
}

.au-flex-content:nth-child(3) h4 {
  color: #E3433E;
}

.au-flex-content:nth-child(5) h4 {
  color: #F6CA4A;
}
/* Add this new rule */
.aboutus-flex-gradient-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, #99205A, #E3433E, #F6CA4A);
  z-index: -1;
}

.au-flex-content h6 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.85rem;
  font-weight: 400;
  line-height: 150%;
  margin: 0 auto;
  width: 70%;
  text-align: center;
}

/* BENEFITS SECTION  */
.benefits-section {
  width: 100%;
}
.benefits-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.benefits-heading {
  background: linear-gradient(90deg, #99205A, #E3433E, #F6CA4A);
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.benefits-heading h3 {
  color: var(--white);
  font-size: 7.45rem;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
  margin: 0;
}
.benefits-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.benefits-flex-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  gap: 1rem;
  padding: 6rem;
  border-bottom: 1px solid var(--white);
  background-color: var(--black);
  transition: 0.25s;
  cursor: pointer;
}
.benefits-flex-box:hover {
  transition: 0.25s;
  background-color: #F6CA4A;
}
.benefits-flex-box:hover div h5,
.benefits-flex-box:hover div p {
  color: var(--black);
}
.benefits-flex-box img,
.benefits-flex-box div h5,
.benefits-flex-box div p {
  transition: color 0.25s;
}
.benefits-flex-box div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.55rem;
}
.benefits-flex-box div img {
  width: 80px;
  height: 80px;
}
.benefits-flex-box div h5 {
  color: var(--white);
  font-size: 3.45rem;
  font-weight: 600;
  line-height: 150%;
  text-transform: uppercase;
  margin: 0;
}
.benefits-flex-box div p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.85rem;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
}

/* CLIENTS SECTION  */
.clients-section {
  width: 100%;
  padding: 10rem 0;
  background-color: var(--black);
}
.client-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 6rem;
}
.client-content h3 {
  color: #F6CA4A;
  font-size: 7.45rem;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
  margin: 0;
}
.clients-swiper-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
.clients-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #F6CA4A;
}
.clients-bar img {
  width: 100%;
  height: auto;
  opacity: 0; /* Hide the image if it's being used for the bar shape */
}
.clients-bar .sm-cb {
  height: 455px;
  width: 100%;
}
.clients-bar .lg-cb {
  width: 100%;
  height: 500px;
}
.client-prev img, .client-next img {
  z-index: 3;
  opacity: 1;
}
.cb-text {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 5rem;
  width: 100%;
  height: 100%;
}
.cb-text.sm-text {
  top: 30%;
}
.cb-text img {
  cursor: pointer;
}
.cb-text .rotate-arrow {
  transform: rotate(180deg);
}
.cb-text h5 {
  color: #99205A;
  font-size: 3.45rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: uppercase;
  transform: rotate(-90deg);
  margin: 0 0 12rem 0;
  white-space: nowrap;
}
.clients-swiper {
  width: 59%;
}
.client-swiper-content {
  width: 100%;
  box-shadow: 0px 20px 60px -9px rgba(237, 27, 36, 0.16);
  position: relative;
  height: 100%;
}
.client-swiper-content img {
  width: 100%;
  height: 100%;
}
.client-swiper-content iframe {
  width: 100%;
  height: 620px;
}
.control-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px !important;
  height: 100px !important;
}
.csc-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 94%;
  margin: 0 auto 0 0;
  bottom: 24px;
  left: 24px;
  position: absolute;
}
.csc-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.25rem;
}
.csc-left h6 {
  color: var(--white);
  font-size: 1.65rem;
  font-weight: 600;
  line-height: 150%;
  text-transform: uppercase;
  margin: 0;
}
.csc-left p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 130%;
  text-transform: uppercase;
  margin: 0;
}
.csc-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.55rem;
}
.csc-right img {
  cursor: pointer;
  width: 48px;
  height: 48px;
}

/* TESTIMONIAL SECTION  */
.testimonial-section {
  width: 100%;
  padding: 10rem 0;
  overflow: hidden;
}
.testimonial-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6rem;
  flex-direction: column;
  width: 100%;
}
.testimonial-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
}
.testimonial-heading h3 {
  background: linear-gradient(90deg, #99205A, #E3433E, #F6CA4A);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 7.45rem;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  margin: 0;
}
.th-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
.th-right span {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #99205A;
  width: 55px;
  height: 55px;
  transform: skewX(-20deg);
  cursor: pointer;
  transition: background-color 0.3s ease;
  
}
.th-right span:hover {
  background-color: #7a1a48; /* A darker shade of #99205A for hover effect */
}

.th-right span img {
  width: 24px;
  height: 24px;
  transform: skewX(20deg);
}

/* Ensure both arrows are visible */
.testi-prev, .testi-next {
  opacity: 1;
  visibility: visible;
}

.testimonial-swiper {
  width: 100%;
}
.swiper-content {
  width: 100%;
  position: relative;
}
.swiper-content img {
  width: 100%;
  height: 100%;
}
.black-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
}
.swiper-text {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.35rem;
  left: 24px;
  bottom: 24px;
}
.swiper-text p {
  color: var(--white);
  font-size: 1.45rem;
  font-weight: 400;
  line-height: 150%;
  margin: 0 auto 0 0;
  width: 92%;
}
.client-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.client-info img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: contain;
}
.client-info div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.25rem;
}
.client-info div h6 {
  color: var(--white);
  font-size: 1.45rem;
  font-weight: 600;
  line-height: 150%;
  margin: 0;
}
.client-info div span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
}
.testimonial-swiper .swiper {
  overflow: visible !important;
}
.testimonial-swiper .swiper-slide {
  height: 100%;
  transition: 0.25s;
}

.testimonial-swiper .centered-slide .swiper-content {
  /* height: 65rem; */
  transition: 0.25s;
  scale: 1.1;
}

/* PARTNERS SECTION  */
.partners-section {
  width: 100%;
  background: linear-gradient(90deg, #99205A, #E3433E, #F6CA4A);
  padding: 1rem 0;
}
.partners-box {
  width: 100%;
}
.partner-marquee {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12rem;
  padding: 0 12rem 0 0;
}

/* SUBSCRIBE SECTION  */
.subscribe-section {
  padding: 8rem 0;
  background: var(--black);
}
.subscribe-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3rem;
  width: 100%;
}
.subscribe-content h4 {
  color: #F6CA4B;
  font-size: 4.45rem;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
  margin: 0;
}
.subscribe-content p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.85rem;
  font-weight: 500;
  line-height: 150%;
  margin: 0;
}
.subscribe-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;
  width: 80%;
  margin: 0 auto 0 0;
}
.subscribe-field input {
  border: 4px solid #F6CA4B;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  transform: skewX(-20deg);
  padding: 0.9rem 4rem;
  color: var(--white);
  font-size: 1.45rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.13);
  height: 52px;
}
.subscribe-field input::placeholder {
  color: var(--white);
  font-size: 1.45rem;
  font-weight: 500;
  text-transform: uppercase;
  transform: skewX(20deg);
}
.subscribe-field input:focus {
  outline: unset;
  box-shadow: unset;
}
.subscribe-status {
  color: #F6CA4B;
  font-size: 1.4rem;
  margin-top: 1rem;
  text-align: center;
}

/* FOOTER  */
footer {
  width: 100%;
  background-color: var(--black);
  padding: 8rem 0;
}
.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.footer-content .padpop {
  width: 180px;
  height: auto;
  cursor: pointer;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.55rem;
}
.social-icons img {
  width: 24px;
  height: auto;
}
.footer-text {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 2rem;
  flex-direction: column;
}
.footer-text p {
  color: var(--white);
  font-size: 1.45rem;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
}
.footer-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
.footer-links a {
  color: var(--white);
  font-size: 1.45rem;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
}
/* MENU  */
.mobile-menu {
  padding: 5rem;
  background-color: var(--black);
  width: 350px;
  height: max-content;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 8rem;
  position: absolute;
  top: 65%;
  right: 4%;
  overflow: hidden;
  z-index: 10000;
}
.desktop-close {
  font-size: 3rem;
  color: var(--white);
  cursor: pointer;
  z-index: 10000;
}
.mobile-menu-top {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.mobile-menu-top img {
  width: 150px;
  height: auto;
}
.mobile-menu-top svg {
  font-size: 3rem;
  color: var(--white);
}
.mobile-menu-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 3rem;
  width: 100%;
  position: relative;
  z-index: 1000;
}
.mobile-menu-bottom a {
  font-size: 2.2rem;
  color: var(--white);
  font-weight: 400;
  margin: 0;
}

/* RESPONSIVENESS  */
@media screen and (max-width: 1320px) {
  .hero-section {
    background-size: auto auto;
    background-position: center center;
    padding: 0 0 20rem 0;
  }
  .header-content img {
    max-height: 75px;
  }
  .header-section.scrolled .header-content img {
    max-height: 40px;
  }
}
@media only screen and (max-width: 1150px) {
  .box {
    padding: 0 3.5rem;
  }
  .hero-content {
    width: 85%;
  }
  .hero-section{
    background-size: auto 100%;
    padding: 0 0 20rem 0;
   }
   .header-content img {
    max-height: 70px;
  }
  .header-section.scrolled .header-content img {
    max-height: 40px;
  }
  .aboutus-content p {
    width: 85%;
  }
  .au-flex-content h6 {
    width: 90%;
  }
  .client-swiper-content iframe {
    width: 100%;
    height: 520px;
  }
  .cb-text {
    top: 46.5%;
  }
  .cb-text h5 {
    font-size: 3rem;
  }
  .benefits-flex-box {
    padding: 5rem 3.5rem;
  }
  /* .testimonial-swiper .centered-slide .swiper-content {
    height: 58rem;
  } */
}
@media only screen and (max-width: 950px) {
  /* .testimonial-swiper .centered-slide .swiper-content {
    height: 70rem;
  } */
   .hero-section{
    background-size: auto 100%;
    padding: 0 0 20rem 0;
   }
   .header-content img {
    max-height: 70px;
  }
  .header-section.scrolled .header-content img {
    max-height: 40px;
  }
}
@media only screen and (max-width: 850px) {
  .box {
    padding: 0 2rem;
  }
  .hero-section{
    padding: 0 0 17rem 0;
   }
  .hero-content h2 {
    font-size: 7.45rem;
  }
  .header-content img {
    max-height: 70px;
  }
  .header-section.scrolled .header-content img {
    max-height: 40px;
  }
  .aboutus-content h3 {
    font-size: 5.45rem;
  }
  .aboutus-content p {
    width: 100%;
    font-size: 1.65rem;
  }
  .au-flex-content h4 {
    font-size: 5.25rem;
  }
  .aboutus-flex hr {
    height: 120px;
  }
  .au-flex-content h6 {
    width: 100%;
    font-size: 1.65rem;
  }
  .benefits-heading h3 {
    font-size: 5.45rem;
  }
  .benefits-heading {
    height: 250px;
  }
  .benefits-flex-box {
    padding: 4rem 2rem;
  }
  .benefits-flex-box div h5 {
    font-size: 2.45rem;
  }
  .benefits-flex-box div p {
    font-size: 1.65rem;
  }
  .benefits-flex-box div img {
    width: 60px;
    height: 60px;
  }
  .benefits-flex-box div {
    gap: 1rem;
  }
  .clients-bar {
    background-color: #F6CA4A; /* Ensure the background color is applied on smaller screens */
    opacity: 1;
  }
  .client-content h3 {
    font-size: 5.45rem;
  }
  .clients-swiper-box {
    gap: 1rem;
  }
  .csc-right {
    gap: 1rem;
  }
  .csc-right img {
    width: 35px;
    height: 35px;
  }
  .testimonial-heading h3 {
    font-size: 5.45rem;
  }
  .th-right span {
    width: 40px;
    height: 40px;
  }
  .swiper-text p {
    font-size: 1.35rem;
  }
  .client-info div h6 {
    font-size: 1.35rem;
  }
  .client-info div span {
    font-size: 1.15rem;
  }
  .partner-marquee {
    gap: 8rem;
    padding: 0 8rem 0 0;
  }
  .subscribe-content h4 {
    font-size: 3.45rem;
  }
  .subscribe-field {
    width: 90%;
  }
  .subscribe-content p {
    font-size: 1.65rem;
  }
  .footer-content .padpop {
    width: 130px;
  }
  .footer-text p {
    font-size: 1.35rem;
  }
  .footer-links a {
    font-size: 1.35rem;
  }
  .header-content .padpop {
    width: 200px;
  }
  .footer-text {
    gap: 1.5rem;
  }
  .marquee-content {
    gap: 3.5rem;
    margin: 0 3.5rem 0 0;
  }
  .hero-section {
    gap: 10rem;
    padding: 0 0 15rem 0;
  }
  .mobile-menu-top {
    display: flex;
  }
  .mobile-menu {
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    padding: 6rem 2rem;
  }
  .mobile-menu-top img {
    width: 200px;
    height: auto;
  }
  /* .testimonial-swiper .centered-slide .swiper-content {
    height: 60rem;
  } */
}
@media only screen and (max-width: 750px) {
  /* .testimonial-swiper .centered-slide .swiper-content {
    height: 60rem;
  } */
  .marquee-box {
    padding: 2.25rem 0;
  }
  header {
    padding: 0rem 0;
  }
  .header-section.scrolled .mobile-menu {
    top: 0%;
  }
  .header-content .padpop {
    width: 150px;
  }
  .header-content .menu-icon {
    width: 35px;
    height: 35px;
  }
  .hero-content {
    width: 100%;
    gap: 3.25rem;
  }
  .primary-btn span {
    font-size: 2rem;
  }
  .primary-btn {
    padding: 0.95rem 3.55rem;
  }
  .hero-content h2 {
    font-size: 4rem;
  }
  .hero-section {
    padding: 0 0 12rem 0;
  }
  .aboutus-section {
    padding: 7.5rem 0;
  }
  .aboutus-content h3 {
    font-size: 3rem;
  }
  .aboutus-content p {
    font-size: 1.45rem;
  }
  .aboutus-flex {
    flex-direction: column;
    gap: 3.35rem;
    margin: 3.35rem 0 0 0;
  }
  .aboutus-flex hr {
    display: none;
  }
  .au-flex-content h4 {
    font-size: 4rem;
    font-weight: 600;
    line-height: 120%;
    margin: 0;
  }
  .au-flex-content h6 {
    font-size: 1.45rem;
    width: 90%;
  }
  .benefits-heading h3 {
    font-size: 3rem;
  }
  .benefits-heading {
    height: 200px;
  }
  .benefits-flex-box div h5 {
    font-size: 2.15rem;
  }
  .benefits-flex-box div p {
    font-size: 1.45rem;
    width: 95%;
    margin: 0 auto 0 0;
  }
  .clients-section {
    padding: 7.5rem 0;
  }
  .client-content h3 {
    font-size: 3rem;
  }
  .clients-swiper-box {
    flex-direction: column;
    gap: 2rem;
  }
  .clients-bar {
    background-color: #F6CA4A; /* Ensure the background color is applied on smaller screens */
    opacity: 1;
  }
  
  .clients-swiper {
    width: 100%;
  }
  .cb-text {
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    flex-direction: row-reverse;
    width: 100%;
    padding: 1.5rem 2rem;
    gap: 2rem;
  }
  .cb-text.lg-text {
    flex-direction: row;
  }
  .cb-text h5 {
    font-size: 2rem;
    transform: unset;
    margin: 0;
    transform: unset;
    width: 100%;
  }
  .cb-text.sm-text {
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
  }
  .cb-text.cb-text-reverse h5 {
    text-align: right;
  }
  .clients-bar .sm-cb {
    display: none;
  }
  .clients-bar .lg-cb {
    display: none;
  }
  .client-swiper-content iframe {
    height: 250px;
  }
  .csc-content {
    bottom: 15px;
    left: 10px;
  }
  .csc-left h6 {
    font-size: 1.35rem;
  }
  .testimonial-section {
    padding: 7.5rem 0;
  }
  .testimonial-heading h3 {
    font-size: 3rem;
  }
  .swiper-text p {
    font-size: 1.25rem;
    width: 95%;
    margin: 0 auto 0 0;
  }
  .partners-section {
    padding: 5.5rem 0;
  }
  .partner-marquee {
    gap: 6rem;
    padding: 0 6rem 0 0;
  }
  .subscribe-section {
    padding: 5.5rem 0;
  }
  .subscribe-content {
    gap: 2rem;
  }
  .subscribe-content h4 {
    font-size: 2.45rem;
  }
  .subscribe-field {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }
  .subscribe-content p {
    font-size: 1.35rem;
  }
  .subscribe-field button {
    margin: 0 auto 0 0;
  }
  footer {
    padding: 5.5rem 0;
  }
  .footer-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }
  .social-icons {
    justify-content: flex-end;
  }
  .footer-text {
    grid-column: span 2;
    align-items: center;
    gap: 1rem;
  }
  .footer-content .padpop {
    width: 150px;
  }
  .th-right span {
    width: 30px;
    height: 30px;
  }
  .swiper-text {
    left: 20px;
    bottom: 20px;
  }
  .subscribe-field input {
    height: unset;
  }
  .mobile-menu {
    padding: 2.25rem 2rem;
  }
  .mobile-menu-top img {
    width: 150px;
  }
  .header-section {
    top: 5%;
  }
  .client-info img {
    width: 40px;
    height: 40px;
  }
}

